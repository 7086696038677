import React from 'react';
import Grid from '@mui/material/Grid2';
import Summary from './Summary';
import FilterForm from '~/components/filters/FilterForm';
import DateRangePicker from '~/components/filters/DateRangePicker';
import {
  useFilterLegArrivalAirport,
  useFilterCreatedDateRange,
  useFilterLegDeptAirport,
  useFilterLegDeptDateRange,
  useFilterVid,
} from '~/views/dashboard/QuotesView/hooks/filters';
import { AircraftFilter } from '~/views/dashboard/QuotesView/QuotesFilterForm/AircraftFilter';
import { AircraftTypeFilter } from '~/views/dashboard/QuotesView/QuotesFilterForm/AircraftTypeFilter';
import { EVENT_NAMES } from '~/analytics/types';
import AirportFilter from '~/views/dashboard/QuotesView/QuotesFilterForm/AirportFilter';
import VidFilter from '~/components/filters/RequestVidFilter';
import { StatusFilter } from '~/views/dashboard/QuotesView/QuotesFilterForm/StatusFilter';

const QuoteFilterForm: React.FC<{ dataTestId?: string }> = ({ dataTestId }) => {
  return (
    <FilterForm summary={<Summary />} dataTestId={dataTestId}>
      <Grid container spacing={5}>
        <Grid container spacing={3} size={6}>
          <Grid size={12}>
            <AirportFilter
              label={'Departure Airport'}
              eventName={'dept_airport'}
              airportFilterFunc={useFilterLegDeptAirport}
              id="dept-airport-filter"
            />
          </Grid>
          <Grid size={12}>
            <AirportFilter
              label={'Arrival Airport'}
              eventName={'arr_airport'}
              airportFilterFunc={useFilterLegArrivalAirport}
              id="arr-airport-filter"
            />
          </Grid>
          <Grid size={12}>
            <AircraftFilter />
          </Grid>
          <Grid size={12}>
            <AircraftTypeFilter />
          </Grid>
        </Grid>
        <Grid container spacing={3} size={6}>
          <Grid size={12}>
            <DateRangePicker
              dateRangeFilterFunc={useFilterCreatedDateRange}
              label="Created Date (from - to)"
              event={EVENT_NAMES.QUOTES_FILTER_CHANGED}
              eventPropName={'created_date'}
            />
          </Grid>
          <Grid size={12}>
            <DateRangePicker
              dateRangeFilterFunc={useFilterLegDeptDateRange}
              label="Leg Departure Date (from - to)"
              event={EVENT_NAMES.QUOTES_FILTER_CHANGED}
              eventPropName={'leg_dept_date'}
            />
          </Grid>
          <Grid size={12}>
            <VidFilter
              filterFunc={useFilterVid}
              event={EVENT_NAMES.QUOTES_FILTER_CHANGED}
              eventPropName={'quote_vid'}
            />
          </Grid>
          <Grid size={12}>
            <StatusFilter />
          </Grid>
        </Grid>
      </Grid>
    </FilterForm>
  );
};

export default QuoteFilterForm;
